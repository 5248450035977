import React from 'react';
import IframeResizer from 'iframe-resizer-react';

const Faq = () => (
    <IframeResizer
        src="https://hammarbyfotboll.imbox.help"
        style={{ width: '1px', minWidth: '100%', minHeight: '70vh' }}
        inPageLinks
    />
);

export default Faq;
